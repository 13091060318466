import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"

import HeaderImage from "../assets/img/new/tlo-serch-01.webp"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Regulations = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Zapoznaj się z regulaminem strony WeNet audyt SEO",
        description:
          "Sprawdź aktualny i szczegółowy regulamin dotyczący darmowego audytu SEO. Znajdziesz w nim wszystkie informacje na temat naszych usług.",
        hreflangs: {
          pl: "regulamin/",
          en: "en/regulations/",
          de: "de/satzung/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/regulamin/",
                name: t("link-text-regulations"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-regulations"),
                text: t("link-text-regulations"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>Regulamin Serwisu Internetowego</h1>
          <p style={{ marginBottom: "40px" }}>audytseo.wenet.pl</p>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
      <section className="standard-section regulations-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2>
                §1 <br /> Wstęp
              </h2>
              <ol>
                <li>
                  <p>
                    Niniejszy Regulamin reguluje zasady i sposób korzystania z Serwisu internetowego{" "}
                    <Link to="/">audytseo.wenet.pl</Link>, którego właścicielem i wydawcą jest spółka WeNet Group Spółka
                    Akcyjna (WeNet Group S.A.) z siedzibą w Warszawie przy ul. Postępu 14 A, zarejestrowana w rejestrze
                    przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy, KRS:
                    0000681163, o kapitale zakładowym w wysokości 4.950.000,00 złotych. Zasady zawarte w niniejszym
                    Regulaminie nie uchybiają regulaminom odnoszącym się do innych usług lub produktów dostępnych w
                    ofercie WeNet Group.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Serwis <Link to="/">audytseo.wenet.pl</Link>
                    &nbsp; umożliwia Użytkownikom przeprowadzenie analizy wyłącznie udostępnionych publicznie kodów
                    źródłowych wybranych stron internetowych, w celu określenia stopnia optymalizacji danej strony pod
                    kątem skuteczności jej wyszukiwania w zasobach sieci Internet.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Ramy prawne przetwarzania przez WeNet Group S.A. i WeNet Sp. z o.o. danych osób fizycznych
                    korzystających z serwisu&nbsp;(Użytkowników) są określone w następujących aktach:&nbsp;
                  </p>
                  <ol>
                    <li>
                      <p>
                        Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                        sprawie&nbsp;ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                        swobodnego&nbsp;przepływu takich danych oraz uchylenia dyrektywy 95/46/WE – zwane dalej
                        RODO;&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        Ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną – zwana dalej
                        UŚUDE.&nbsp;
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
              <h2>
                §2 <br /> Definicje
              </h2>
              <ol>
                <li>
                  <p>
                    <strong>Użytkownik</strong> - oznacza każdą osobę fizyczną, która za pośrednictwem sieci Internet
                    korzysta z usług&nbsp;oferowanych przez Serwis.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Użytkownik Zdefiniowany</strong> – oznacza Użytkownika, który przesłał do WeNet Group
                    dotyczące go&nbsp;dane osobowe poprzez Formularz „Optymalizuj Swoją Stronę WWW” lub narzędzia live
                    chat.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>WeNet Group/Usługodawca</strong> - oznacza spółkę WeNet Group S.A. z siedzibą w Warszawie, o
                    której mowa w § 1.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Oprogramowanie Analityczne</strong> – oznacza oprogramowanie wykorzystywane przez Serwis,
                    umożliwiające Użytkownikowi przeprowadzenie Audytu SEO, należące do WeNet Group i podlegające
                    ochronie na podstawie ustawy „o prawie autorskim i prawach pokrewnych" z dnia 04 lutego 1994
                    r.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Serwis</strong> – oznacza stronę internetową o adresie <Link to="/">audytseo.wenet.pl</Link>{" "}
                    umieszczoną na serwerze WeNet Group, zawierającą:
                  </p>
                  <ol>
                    <li>
                      <p>Oprogramowanie Analityczne;&nbsp;</p>
                    </li>
                    <li>
                      <p>
                        Formularz „Zoptymalizuj Swoją Stronę WWW” umożliwiający otrzymanie Raportu Audytu SEO w formie
                        pliku PDF i nawiązanie przez Użytkownika kontaktu handlowego z WeNet Group;&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        Narzędzie live chat umożliwiające bezpośredni, pisemny kontakt z konsultantami WeNet Group w
                        godzinach 8.00 – 16.00 oraz kontakt off-line w godzinach 16.00 – 8.00;
                      </p>
                    </li>
                    <li>
                      <p>
                        Narzędzie live chat umożliwiające bezpośredni kontakt z konsultantami WeNet Group drogą
                        telefoniczną w godzinach 8.00 – 16.00 oraz kontakt off-line w godzinach 16.00 – 8.00;
                      </p>
                    </li>
                    <li>
                      <p>
                        Przycisk Kontakt przenoszący Użytkownika na stronę{" "}
                        <a href="http://wenet.pl" target="_blank" rel="nofollow noreferrer">
                          wenet.pl
                        </a>{" "}
                        i umożliwiający nawiązanie kontaktu&nbsp;poprzez formularze kontaktowe na stronie docelowej,
                        zgodnie z Regulaminem strony{" "}
                        <a href="http://wenet.pl" target="_blank" rel="nofollow noreferrer">
                          wenet.pl
                        </a>
                        .
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>Informacja handlowa</strong> – wiadomość przesyłana pocztą elektroniczną, zawierająca Raport
                    Audytu SEO w formie PDF oraz informacje dotyczące realizacji Usług SEO w odniesieniu do stron www
                    zarządzanych przez Użytkownika, wobec których wykonał on Audyt SEO. Użytkownik wyraża zgodę na
                    otrzymanie Informacji handlowej poprzez wypełnienie Formularza Kontaktowego.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Audyt/Raport SEO </strong>- analiza udostępnionego publicznie kodu źródłowego pojedynczej,
                    wybranej przez Użytkownika strony internetowej, w celu określenia stopnia optymalizacji strony pod
                    kątem skuteczności wyszukiwania jej w zasobach sieci Internet.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Raport Audytu SEO/Raport</strong> – wynik przeprowadzonego Audytu prezentowany Użytkownikowi
                    na ekranie jego urządzenia końcowego lub przesyłany Użytkownikowi na jego życzenie pocztą
                    elektroniczną w formie pliku PDF.&nbsp; Raport Audytu SEO w formie pliku PDF zawiera więcej
                    informacji niż Raport Audytu SEO prezentowany na ekranie urządzenia końcowego, w tym merytoryczne
                    opisy każdego sprawdzanego kryterium Audytu.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Urządzenie końcowe</strong> – komputer, tablet lub telefon posiadający połączenie z siecią
                    Internet, z którego korzysta Użytkownik.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Usługi SEO</strong> – usługi świadczone przez WeNet Group, polegające na optymalizacji stron
                    WWW pod kątem zwiększenia skuteczności ich wyszukiwania w zasobach sieci Internet.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Pliki „cookies”</strong> – pliki tekstowe zawierające dane informatyczne. Pliki „cookies” są
                    stosowane najczęściej w przypadku liczników, sond, sklepów internetowych, stron wymagających
                    logowania, reklam i do monitorowania aktywności odwiedzających.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Regulamin</strong> – niniejszy Regulamin Serwisu Internetowego{" "}
                    <Link to="/">audytseo.wenet.pl</Link>
                    .&nbsp;
                  </p>
                </li>
              </ol>
              <h2>
                §3 <br /> Rodzaje i zakres Usług świadczonych drogą elektroniczną na rzecz Użytkowników
              </h2>
              <ol>
                <li>
                  <p>WeNet Group w ramach Serwisu bezpłatnie świadczy Użytkownikom następujące Usługi:&nbsp;</p>
                  <ol>
                    <li>
                      <p>Wykonanie Audytu SEO z wyświetleniem Raportu na ekranie Urządzenia końcowego;&nbsp;</p>
                    </li>
                    <li>
                      <p>
                        Wykonanie Audytu SEO z przesłaniem Raportu w formie PDF na adres e-mail Użytkownika wskazany
                        przez&nbsp;niego w Formularzu Kontaktowym wraz z wstępną Ofertą handlową dotyczącą Usługi
                        SEO;&nbsp;
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Serwis nie prowadzi bezpośredniej sprzedaży Usług SEO. Realizacja Usług SEO przez WeNet Group
                    następuje na&nbsp;podstawie umów zawieranych z klientami poza Serwisem.&nbsp;
                  </p>
                </li>
              </ol>
              <h2>
                §4 <br /> Zasady korzystania z serwisu
              </h2>
              <ol>
                <li>
                  <p>
                    WeNet Group upoważnia Użytkownika do korzystania z Serwisu i wykonywania Audytu SEO wyłącznie w
                    ograniczonym zakresie, wynikającym z postanowień niniejszego Regulaminu.&nbsp;
                  </p>
                </li>
                <li>
                  <p>Warunkami technicznymi korzystania z Serwisu są:&nbsp;</p>
                  <ol>
                    <li>
                      <p>dostęp do sieci Internet;&nbsp;</p>
                    </li>
                    <li>
                      <p>
                        zainstalowana w urządzeniu końcowym przeglądarka internetowa, na przykład jedna z następujących:
                        Firefox, Chrome, Safari, IE, Opera, zaktualizowana do najnowszej wersji.&nbsp;
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Wszelkie prawa do Oprogramowania Analitycznego przysługują WeNet Group.&nbsp;</p>
                </li>
                <li>
                  <p>
                    Użytkownik korzystając z Serwisu nie nabywa żadnych praw, w szczególności praw autorskich czy
                    licencji, do treści zawartych w Serwisie, które pozostają własnością WeNet Group i jej kontrahentów.
                    Oprogramowanie Analityczne nie może być elektronicznie pobierane przez Użytkownika, ani
                    wykorzystywane do składania jakichkolwiek ofert handlowych osobom trzecim.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Użytkownik nie jest uprawniony do odpłatnego bądź nieodpłatnego udostępniania osobom trzecim
                    Raportów generowanych przez Oprogramowanie Analityczne. Użytkownik nie jest także uprawniony do
                    generowania za pośrednictwem oprogramowania zapytań i wykonywania operacji inżynierii zwrotnej wobec
                    mechanizmu Oprogramowania Analitycznego rozpowszechniania w jakiejkolwiek formie pozyskanych w ten
                    sposób części lub całości Raportów.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Prawa do utworów, znaków towarowych oraz Oprogramowania Analitycznego udostępnionych w Serwisie
                    przysługują WeNet Group lub osobom trzecim. W przypadku, gdy przedmiotowe prawa przysługują osobom
                    trzecim, WeNet Group prezentuje w Serwisie utwory, znaki towarowe oraz bazy danych na podstawie
                    stosownych umów zawartych z tymi osobami, w szczególności umów licencyjnych i umów o przeniesienie
                    autorskich praw majątkowych, lub na podstawie obowiązujących w tym zakresie przepisów prawa.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Informacje handlowe przesyłane do Użytkownika Zdefiniowanego nie stanowią oferty w rozumieniu
                    przepisów Kodeksu Cywilnego. Stanowią wyłącznie zaproszenie dla Użytkownika do złożenia zapytania
                    ofertowego do WeNet Group lub upoważniają WeNet Group do nawiązania kontaktu handlowego z
                    Użytkownikiem.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Zakazane jest umieszczanie przez Użytkowników przy użyciu narzędzi komunikacyjnych Serwisu treści o
                    charakterze bezprawnym, sprzecznych z dobrymi obyczajami oraz naruszających prawa osób
                    trzecich.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group zastrzega możliwość wprowadzenia ograniczeń w dostępie do Serwisu bez podania przyczyny,
                    w szczególności wobec Użytkowników naruszających zasady korzystania z Serwisu.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group zastrzega sobie prawo do czasowego zawieszenia świadczenia Usług w związku z
                    modernizacją lub przebudową Serwisu albo z pracami konserwatorskimi systemu
                    teleinformatycznego.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group nie odpowiada za przerwy w świadczeniu Usług wynikające z przyczyn od niego
                    niezależnych, w szczególności działania siły wyższej.&nbsp;
                  </p>
                </li>
              </ol>
              <h2>
                §5 <br /> Warunki zawierania i rozwiązywania umowy z WeNet Group o świadczenie Usług drogą elektroniczną
              </h2>
              <p>
                W przypadku każdego Użytkownika Serwisu, zawarcie i rozwiązanie umowy o świadczenie Usług drogą
                elektroniczną następuje poprzez każdorazowe przeglądanie treści Serwisu i korzystanie z Usług dostępnych
                dla Użytkownika.&nbsp;
              </p>
              <h2>
                §6 <br /> Odpowiedzialność
              </h2>
              <ol>
                <li>
                  <p>WeNet Group nie ponosi odpowiedzialności:&nbsp;</p>
                  <ol>
                    <li>
                      <p>
                        za podanie przez Użytkownika Zdefiniowanego danych niezgodnych ze stanem faktycznym,
                        niekompletnych lub nieaktualnych;&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        za szkody spowodowane udostępnieniem przez Użytkownika w narzędziach live chat informacji o
                        których mowa w ust.2.&nbsp;
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Każdy Użytkownik ponosi pełną odpowiedzialność za złamanie prawa bądź szkodę wywołaną jego
                    działaniami w Serwisie, w szczególności podaniem nieprawdziwych danych, ujawnieniem prawnie
                    chronionej tajemnicy lub innej informacji poufnej, naruszeniem dóbr osobistych lub praw własności
                    intelektualnej osób trzecich.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group nie ponosi odpowiedzialności, w tym w szczególności odszkodowawczej, wobec
                    jakiegokolwiek Użytkownika za jakiekolwiek straty wynikające z nieprawidłowości, nieaktualności, lub
                    jakości danych zawartych w Raportach Audytu SEO.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group nie ponosi odpowiedzialności za działania osób trzecich lub jakichkolwiek Użytkowników,
                    którzy w sposób niezgodny z zasadami korzystania z Serwisu wykorzystują Raporty Audytu SEO.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    W żadnym przypadku WeNet Group nie bierze odpowiedzialności za jakiekolwiek uszkodzenia lub wirusy,
                    które mogą zainfekować sprzęt jakiegokolwiek Użytkownika podczas korzystania z Serwisu, a w
                    szczególności w związku z pobieraniem z niego jakichkolwiek materiałów, plików, materiałów
                    tekstowych, zdjęciowych, graficznych, dźwiękowych lub filmowych.&nbsp;
                  </p>
                </li>
              </ol>
              <h2>
                §7 <br /> Statystyki
              </h2>
              <p>
                WeNet Group zastrzega prawo zbierania i przetwarzania anonimowych statystyk dotyczących Użytkowników, w
                celu poznania ich preferencji i oceny respektowania zasad korzystania z Serwisu oraz Regulaminu.
                Powyższe dane nie stanowią danych osobowych w rozumieniu stosownych przepisów prawa i nie podlegają
                ochronie w nich przewidzianej.&nbsp;
              </p>
              <h2>
                §8 <br /> Postanowienia dotyczące plików „cookies”
              </h2>
              <p>
                Szczegółowe informacje dotyczące stosowanych przez WeNet technologii, w tym rodzajów i celów korzystania
                z plików cookies dostępne są w <Link to="/polityka-cookies">Polityce cookies</Link>.
              </p>
              <h2>
                §9 <br /> Zasady przetwarzania danych osobowych
              </h2>
              <ol>
                <li>
                  <p>
                    WeNet Group S.A. z siedzibą w Warszawie, pod adresem: ul Postępu 14 A, 02-676 Warszawa,
                    zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII
                    Wydział Gospodarczy Krajowego Rejestru Sądowego, KRS: 0000681163, o kapitale zakładowym w wysokości
                    4.950.000,00 złotych, jest w rozumieniu przepisów RODO administratorem danych oraz WeNet Sp. z o.o.
                    z siedzibą w Warszawie, pod adresem: ul Postępu 14 A, 02-676 Warszawa, zarejestrowana w rejestrze
                    przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy
                    Krajowego Rejestru Sądowego, KRS: 0000116894, o kapitale zakładowym w wysokości 12.469.000,00
                    złotych, są w rozumieniu przepisów RODO administratorami danych: &nbsp;
                  </p>
                  <ol>
                    <li>
                      <p>
                        Użytkowników Zdefiniowanych, w zakresie danych udostępnionych WeNet Group S.A. i WeNet Sp. z
                        o.o. w Formularzu Kontaktowym lub przy użyciu narzędzi live chat tj imię, nazwisko, nazwa i NIP
                        reprezentowanej firmy, adres e-mail, numer telefonu stacjonarnego lub komórkowego, treści
                        korespondencji prowadzonej z WeNet Group S.A. i WeNet Sp. z o.o. przy użyciu narzędzi live chat
                        oraz plików „cookies”.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Dane Użytkowników Zdefiniowanych są przetwarzane:&nbsp;</p>
                  <ol>
                    <li>
                      <p>
                        w celu świadczenia przez WeNet Group S.A. i WeNet Sp. z o.o. usług drogą elektroniczną, o
                        których mowa w niniejszym&nbsp;Regulaminie, w rozumieniu art. 6 ust. 1 lit. b RODO oraz na
                        podstawie zgody udzielonej w trybie art. 6 ust. 1 lit. a RODO, która może być wycofana przez
                        podmiot w dowolnym czasie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na
                        podstawie zgody przed jej cofnięciem.&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        w celu realizacji działań marketingowych WeNet Group S.A. i WeNet Sp. z o.o. w ramach prawnie
                        uzasadnionego interesu&nbsp;administratora zgodnie z art. 6 ust. 1 lit. f RODO zgodnie z
                        oświadczeniami woli Klienta dotyczącymi&nbsp;przetwarzania danych i komunikacji marketingowej
                        określonymi w RODO oraz w Ustawie o świadczeniu usług drogą elektroniczną i Prawie
                        Telekomunikacyjnym. Zgody udzielone WeNet Group S.A. i WeNet Sp. z o.o. w zakresie wyżej
                        wymienionej komunikacji marketingowej mogą być wycofane przez Klienta w dowolnym czasie, bez
                        wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
                        cofnięciem.&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        w celu realizacji obowiązków prawnych WeNet Group S.A. i WeNet Sp. z o.o. wobec podmiotu,
                        którego dane dotyczą określonych w RODO, w rozumieniu art. 6 ust 1 lit. c RODO, o których mowa w
                        ust. 6 poniżej.&nbsp;
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Dane Użytkowników Zdefiniowanych mogą być udostępnione na żądanie uprawnionych organów państwowych.
                    Ponadto, pliki „cookies” ich dotyczące mogą być udostępniane reklamodawcom i partnerom biznesowym
                    współpracującym z WeNet Group S.A. i WeNet Sp. z o.o.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Dane udostępnione przez Użytkownika Zdefiniowanego są przetwarzane przez WeNet Group S.A. i WeNet
                    Sp. z o.o. do czasu zgłoszenia przez Użytkownika wycofania zgody na komunikację marketingową, o
                    której mowa w ust. 2 pkt b. Ponadto Dane Użytkowników Zdefiniowanych są przetwarzane przez okres
                    wymagany do wykonania i udokumentowania przez WeNet Group S.A. i WeNet Sp. z o.o. obowiązków
                    prawnych nałożonych przez przepisy RODO wobec Użytkowników i Użytkowników Zarejestrowanych, o
                    których mowa w ust. 6.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Każdy podmiot, którego dane są administrowane przez WeNet Group S.A. i WeNet Sp. z o.o. ma prawo
                    żądania od WeNet Group S.A. i WeNet Sp. z o.o. dostępu do danych, które go dotyczą, ich
                    sprostowania, usunięcia lub ograniczenia przetwarzania oraz prawo do wniesienia sprzeciwu wobec
                    przetwarzania, a także prawo do przenoszenia danych.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Kontakt z Inspektorem Ochrony Danych WeNet Group S.A. i WeNet Sp. z o.o.: Wojciech Zaręba,{" "}
                    <a href="mailto:iod@wenet.pl">iod@wenet.pl</a>.
                  </p>
                </li>
                <li>
                  <p>
                    Każdy Użytkownik, którego dane są administrowane przez WeNet Group S.A. i WeNet Sp. z o.o. ma prawo
                    do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group S.A. i WeNet Sp. z o.o. przetwarzają dane osobowe w systemach teleinformatycznych, do
                    których dostęp jest ściśle ograniczony. WeNet Group stosuje środki techniczne i organizacyjne mające
                    na celu zapewnienie bezpieczeństwa danych osobowych przetwarzanych w Serwisie, w tym ochronę przed
                    ujawnieniem danych osobom nieuprawnionym. Systemy teleinformatyczne oraz procedury stosowane przez
                    WeNet Group są regularnie monitorowane w celu wykrycia ewentualnych zagrożeń.&nbsp;
                  </p>
                </li>
              </ol>
              <h2>
                §10 <br /> Postępowanie reklamacyjne
              </h2>
              <ol>
                <li>
                  <p>
                    Reklamacje związane z funkcjonowaniem Serwisu oraz pytania dotyczące użytkowania Serwisu należy
                    składać na adres poczty elektronicznej <a href="mailto:dok@wenet.pl">dok@wenet.pl</a>. Zgłoszenie
                    reklamacyjne powinno zawierać oznaczenie danych osoby wnoszącej reklamację (imię, nazwisko, adres)
                    oraz uzasadnienie reklamacji.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    WeNet Group rozpatrzy reklamację w terminie 30 dni kalendarzowych od dnia jej otrzymania oraz
                    poinformuje niezwłocznie Użytkownika, za pomocą poczty elektronicznej, o sposobie jej rozpatrzenia.
                    W sytuacji, gdy podane w treści reklamacji informacje wymagają uzupełnienia, czas rozpatrzenia
                    reklamacji może ulec przedłużeniu.&nbsp;
                  </p>
                </li>
                <li>
                  <p>Użytkownikowi nie przysługuje prawo odwołania się od treści odpowiedzi na reklamację.&nbsp;</p>
                </li>
              </ol>
              <h2>
                §11 <br /> Postanowienia końcowe
              </h2>
              <ol>
                <li>
                  <p>Użytkownik korzystając z Serwisu akceptuje treść niniejszego Regulaminu.&nbsp;</p>
                </li>
                <li>
                  <p>
                    WeNet Group zastrzega prawo do jednostronnej zmiany postanowień niniejszego Regulaminu w każdym
                    czasie, poprzez opublikowanie aktualnej wersji w Serwisie.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Zasady korzystania z innych serwisów Usługodawcy regulują odrębne regulaminy opublikowane w tych
                    serwisach.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    W sprawach nieuregulowanych w niniejszym Regulaminie, zastosowanie mają obowiązujące przepisy prawa
                    polskiego.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Do rozstrzygania sporów wynikających ze świadczenia Usług określonych niniejszym Regulaminem
                    właściwy jest sąd powszechny miejsca siedziby Usługodawcy.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Użytkownicy mają zagwarantowany dostęp do niniejszego Regulaminu w każdym czasie, za pośrednictwem
                    odsyłacza zamieszczonego na stronie głównej Serwisu, w formie, która umożliwia jego pobranie,
                    utrwalenie i wydrukowanie.&nbsp;
                  </p>
                </li>
                <li>
                  <p>Niniejszy Regulamin wchodzi w życie z dniem opublikowania w Serwisie.&nbsp;</p>
                </li>
              </ol>
              <p>Regulamin obowiązuje od dnia 01.01.2023</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Regulations
